.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav .nav__nav-items a::after,
.nav .nav__mobile-nav-ctas button::after,
.nav .nav__hamburger > button.hamburger::after,
.nav .nav__nav-items button::after {
  border: 0;
}

.nav__nav-items {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  margin-right: 2rem;
}

.nav__nav-items--hide {
  display: none;
  visibility: hidden;
}

.nav__mobile-nav-ctas {
  margin-top: 0.2rem;
}

.nav__mobile-nav-ctas .nav__lets-talk,
.nav__nav-items a,
.nav__nav-items .nav__lets-talk {
  height: 3.2rem;
  padding: 0.8rem 2rem;
  margin: 0 1rem;
  border-radius: 1.2rem;
  color: var(--purple);
  font-size: 1.5rem;
  white-space: nowrap;
  transition: all 0.15s linear;
}

.nav__nav-items .nav__lets-talk {
  line-height: 1.6rem;
}

.nav__mobile-nav-ctas .nav__lets-talk {
  margin: 0 2rem 0 0;
  padding: 0.5rem 2rem;
}

.nav__mobile-nav-ctas .nav__lets-talk,
.nav__nav-items .nav__lets-talk {
  color: var(--light);
  background: var(--vivid-purple);
  border-radius: 1.2rem;
}

.nav__mobile-nav-ctas .nav__lets-talk:hover,
.nav__nav-items .nav__lets-talk:hover {
  color: var(--light);
  background: var(--vivid-purple__hover);
}

.nav__nav-items .nav__signin {
  color: var(--vivid-purple);
  background: var(--light);
  border: 1px solid var(--vivid-purple);
  border-radius: 1.2rem;
}

.nav__nav-items .nav__signin:hover {
  color: var(--light);
  background: var(--vivid-purple__hover);
}

.nav__nav-items a:hover {
  color: var(--link-change);
}

.nav__nav-items a.active {
  color: var(--link-change);
  background: var(--shade2_100);
}

@media (max-width: 1050px) {
  .nav__nav-items {
    display: none;
  }
}

@media (min-width: 1050px) {
  .nav__mobile-nav-ctas {
    display: none;
  }
}
