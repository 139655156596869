.nav__hamburger {
  height: 28px;
}
.hamburger {
  position: relative;
  width: 24px;
  height: 28px;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  outline: 0;
  cursor: pointer;
  transition: var(--transition);
}
.hamburger h2 {
  display: block;
  position: absolute;
  width: 24px;
  height: 3px;
  margin: 0 auto;
  border-radius: 1px;
  background: var(--secondary);
  color: var(--secondary);
  font-size: 0;
}

.hamburger:hover h2 {
  background: var(--link-change);
  color: var(--link-change);
}

.hamburger h2::before,
.hamburger h2::after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  width: 24px;
  height: 3px;
  border-radius: 1px;
  background: currentColor;
}

.hamburger h2::before {
  top: -9px;
}

.hamburger h2::after {
  top: 9px;
}

@media (min-width: 1050px) {
  .nav__hamburger {
    display: none;
  }
}
