a.header__logo.active,
a.header__logo {
  display: flex;
  align-items: center;
  letter-spacing: 0.1rem;
  font-size: 2.5rem;
  color: var(--primary);
}

a.header__logo::after {
  border: 0;
}

.header__logo .inline-svg__parent {
  margin-right: 1.2rem;
}

.header__logo-img {
  display: flex;
  flex-shrink: 0;
}
.header__logo-img svg {
  width: auto;
  height: 2.3rem;
  transition: all 0.1s ease-in-out;
}

.header__logo-img svg * {
  fill: var(--primary);
}

@media (max-width: 768px) {
  a.header__logo.active,
  a.header__logo {
    font-size: 2rem;
  }
  .header__logo-img svg {
    height: 22px;
  }
}
