.popover-nav {
  position: absolute;
  top: 1.5vh;
  right: 2%;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  perspective: 2000px;
}

.popover-nav {
  width: 40rem;
  min-width: 30rem;
  max-width: 96%;
}

.popover-nav__container {
  position: relative;
  border-radius: 1rem;
  line-height: 40px;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  font-size: 17px;
  white-space: nowrap;
  flex-grow: 1;
  background: var(--light);
  opacity: 0;
  transform: scale(0.95);
  transform-origin: 100% 0;
  transition-duration: 0.25s;
}

.popover-nav__container--active {
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

.popover-nav__close {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 51px;
  height: 51px;
  font-size: 0;
  color: var(--secondary);
  cursor: pointer;
  outline: 0;
}

.popover-nav__close:hover {
  color: var(--link-change);
}

.popover-nav__close::before,
.popover-nav__close::after {
  content: '';
  position: absolute;
  background: currentColor;
  border-radius: 1px;
  left: 14px;
  right: 14px;
  top: 24px;
  height: 3px;
  transform: rotate(45deg);
  transition: background 0.1s;
}

.popover-nav__close::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.popover-nav__contacts {
  padding: 20px 0 15px;
}

.popover-nav__contacts h4 {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  color: var(--secondary);
  text-transform: uppercase;
  margin: -5px 0 0 26px;
}

.popover-nav__contact-list {
  display: flex;
  flex-wrap: wrap;
}

.popover-nav__nav-links ul,
.popover-nav__contact-list ul {
  flex-grow: 1;
}

.popover-nav__nav-links ul:last-child,
.popover-nav__contact-list ul:last-child {
  flex-grow: 3;
}

.popover-nav__nav-links li,
.popover-nav__contact-list li {
  list-style: none;
  position: relative;
  max-width: 300px;
}

.popover-nav__nav-links li a {
  display: block;
  padding: 0 20px;
  min-width: 100px;
  max-width: 300px;
  border-radius: 1.2rem;
  color: var(--purple);
}

.popover-nav__contact-list li a {
  padding: 0 26px;
  font-size: 19px;
  font-weight: bold;
  line-height: 50px;
  color: var(--purple);
}

.popover-nav__nav-links li a:hover,
.popover-nav__contact-list li a:hover {
  color: var(--vivid-purple);
}

.popover-nav__nav-links li a.active {
  color: var(--link-change);
  background: var(--shade2_100);
  pointer-events: none;
}

.popover-nav__contacts svg {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin: 0 10px 0 -3px;
  vertical-align: -6px;
}

.popover-nav__nav-links {
  border-top: 2px solid #f6f9fc;
  padding: 15px 10px;
  display: flex;
  flex-wrap: wrap;
}

a.popover-nav__signin {
  display: block;
  padding: 12px 30px;
  color: var(--purple);
  background: var(--shade2);
  font-weight: 600;
}

a.popover-nav__signin:hover {
  color: var(--vivid-purple);
}

.popover-nav__badge {
  display: inline-block;
  margin-left: 5px;
  vertical-align: 2px;
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1;
  font-weight: 700;
  background: var(--vivid-purple);
  transition: background 0.15s;
  border-radius: 10px;
  padding: 2px 4px 1px;
  height: auto;
  top: auto;
  box-shadow: none;
}
