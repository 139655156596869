.header {
  position: relative;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: var(--faint);
  box-shadow: 0 1px 5px 1px rgba(65, 65, 65, 0.05);
  z-index: 4;
}

.header__container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1600px;
  height: 7.5rem;
  padding: 0 4rem;
  margin: 0 auto;

  opacity: 0.97;
  z-index: 4;
  transition: opacity 0.5s linear, visibility 0.5s ease-out, top 0.5s linear;
}

.header__container--center {
  justify-content: center;
}

@media (max-width: 925px) {
  .header__container {
    padding: 0 15px;
  }
}
